import { SubmitButton } from 'components/form'
import { H1 } from 'components'
import { Text } from 'components'
import { Form, Formik } from 'formik'
import { useNotify, useTranslations, useValidation } from 'hooks'
import { configureAmplifyAuth, fetchCognitoConfig } from 'libs/api'
import React, { useState } from 'react'
import { SubdomainField } from './SubdomainField'
import translations from './SubdomainForm.i18n.json'
import * as routes from 'routes'
import { ROOT_DOMAIN_DEV, ROOT_DOMAIN_PROD } from 'utils/isRootDomain'
import { AuthLayout } from 'components/layouts/AuthLayout/AuthLayout'

export const SubdomainForm: React.FC = ({ children }) => {
  const [isConfigured, setIsConfigured] = useState(false)
  const t = useTranslations(translations)
  const notify = useNotify()
  const validationSchema = useValidation((rules) =>
    rules.object({
      subdomain: rules.string().required().label(t.subdomainLabel),
    })
  )

  const domain = window.location.hostname === ROOT_DOMAIN_DEV ? ROOT_DOMAIN_DEV : ROOT_DOMAIN_PROD
  const handleSubmit = async (values: { subdomain: string }) => {
    const origin = `https://${values.subdomain}.${domain}`
    try {
      const cognitoConfig = await fetchCognitoConfig({ origin })
      if (cognitoConfig) {
        if (window.location.pathname.startsWith(routes.teamsNotifications)) {
          // Special flow for Microsoft Teams Configuration Page:
          configureAmplifyAuth(cognitoConfig)
          setIsConfigured(true)
          return
        }
        window.location.href = origin
      } else {
        notify.error(t.invalidSubdomain)
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : 'An error occurred'
      notify.error(message)
    }
  }

  if (isConfigured) return <>{children}</>
  return (
    <AuthLayout>
      <H1 shade={1}>{t.title}</H1>
      <Text marginTop={3}>{t.subtitle}</Text>

      <Formik initialValues={{ subdomain: '' }} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Form>
          <SubdomainField name="subdomain" my={12} domain={domain} />
          <SubmitButton fontSize="sm" mb={6}>
            {t.continue}
          </SubmitButton>
        </Form>
      </Formik>
    </AuthLayout>
  )
}
