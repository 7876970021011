import { useEffect, useRef } from 'react'
import { WebSocketHook } from 'react-use-websocket/dist/lib/types'

const pingMaxTime = 3000

export const usePingCheck = ({ lastJsonMessage, sendJsonMessage, getWebSocket }: WebSocketHook) => {
  const lastPingResponseDate = useRef(new Date())

  useEffect(() => {
    let pingTimeout: number
    let checkTimeout: number
    function schedulePing() {
      pingTimeout = window.setTimeout(ping, 30 * 1000)
    }
    function ping() {
      sendJsonMessage({ action: 'ping' })
      const pingTime = Date.now()
      checkTimeout = window.setTimeout(() => {
        if (lastPingResponseDate.current.getTime() < pingTime) {
          console.log('Ping not responded')
          const ws = getWebSocket()
          if (ws) {
            ws.close() // let it reconnect
          }
        }
      }, pingMaxTime)
      schedulePing()
    }
    schedulePing()
    return () => {
      window.clearTimeout(pingTimeout)
      window.clearTimeout(checkTimeout)
    }
  }, [sendJsonMessage, getWebSocket])

  useEffect(() => {
    if (lastJsonMessage?.action === 'ping') {
      lastPingResponseDate.current = new Date()
    }
  }, [lastJsonMessage])
}
