import { Flex } from '@chakra-ui/react'
import { Stack } from 'components'
import { CancelButton, ColorSelectField, SelectField, SubmitButton, TextareaField, TextField } from 'components/form'
import { PhotoUploadField } from 'components/form/PhotoUploadField'
import { Form, Formik } from 'formik'
import { useNotify, useQuery, useTranslations, useValidation } from 'hooks'
import { createDevice, DeviceFormValues, getDeviceTypesList, updateDevice } from 'libs/api'
import { DeviceItem } from 'libs/api/devices'
import { sample } from 'lodash-es'
import React, { useMemo } from 'react'
import { GeocodingFeature } from 'types'
import { paletteColorOptions } from 'utils/palette'
import translations from './DeviceForm.i18n.json'

interface Props {
  item?: DeviceItem
  onSuccess: (savedItem: DeviceItem) => void
  onCancel: () => void
}

function getLocationObject(device: DeviceItem): GeocodingFeature {
  return {
    place_name: device.place_name,
    geometry: {
      type: '',
      coordinates: [device.lng, device.lat],
    },
    place_type: [],
    properties: {},
  }
}

export function DeviceForm({ onSuccess, item, onCancel }: Props) {
  const t = useTranslations(translations)
  const validationSchema = useValidation((rules) =>
    rules.object({
      name: rules.string().required().label(t.deviceName),
      thing_type_id: rules.string().required().label(t.deviceType),
    })
  )

  const snackbar = useNotify()
  const { data: deviceTypes, isFetching } = useQuery(getDeviceTypesList)

  const deviceTypeOptions = useMemo(
    () => (deviceTypes?.items || []).map((item) => ({ label: item.name, value: item.thing_type_id })),
    [deviceTypes]
  )

  const onSubmit = async (values: DeviceFormValues) => {
    const payload = { ...values, color: values.color || sample(paletteColorOptions)?.value }

    try {
      const savedItem = await (item ? updateDevice(item, payload) : createDevice(payload))
      snackbar.success(item ? t.editSuccess : t.createSuccess)
      onSuccess(savedItem)
    } catch (e: any) {
      snackbar.error(e)
    }
  }

  const initialValues: DeviceFormValues = {
    image_url: item?.image_url || '',
    color: item?.color || '',
    description: item?.description || '',
    name: item?.name || '',
    hardware_version: item?.hardware_version || '',
    firmware_version: item?.firmware_version || '',
    location: item ? getLocationObject(item) : undefined,
    thing_type_id: item ? deviceTypeOptions.find((o) => o.label === item.thing_type_name)?.value : undefined,
    local_ip_address: item?.local_ip_address || '',
    device_string: item?.device_string || '',
    mac_address: item?.mac_address || '',
    certificate_cn: item?.certificate_cn || '',
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize validationSchema={validationSchema}>
      <Form>
        <Flex direction="column">
          <Stack spacing={6} mt={6}>
            <TextField name="name" label={t.deviceName} />
            <SelectField
              options={deviceTypeOptions}
              name="thing_type_id"
              placeholder={t.deviceType}
              isLoading={isFetching}
            />
            <TextareaField name="description" label={t.description} placeholder={t.descriptionPlaceholder} />
            <PhotoUploadField name="image_url" label={t.photoUpload} />
            <TextField name="local_ip_address" label={t.localIpAddress} />
            <TextField name="device_string" label={t.deviceString} />
            <TextField name="mac_address" label={t.macAddress} />
            <TextField name="certificate_cn" label={t.certificateCn} />
            {/* <GeocoderField name="location" placeholder={t.location} label={t.location} /> */}
            <TextField name="firmware_version" label={t.firmwareVersion} />
            <TextField name="hardware_version" label={t.hardwareVersion} />

            <ColorSelectField options={paletteColorOptions} name="color" placeholder={t.chooseAColor} />
            <Flex justify="flex-end" align="center">
              <CancelButton mr={4} onClick={onCancel} />
              <SubmitButton>{item ? t.updateDevice : t.addDevice}</SubmitButton>
            </Flex>
          </Stack>
        </Flex>
      </Form>
    </Formik>
  )
}
