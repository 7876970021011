import { flatten, chunk } from 'lodash-es'
import {
  UseQueryOptions,
  useQuery as _useQuery,
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  QueryFunctionContext,
  InfiniteData,
} from 'react-query'
import { ListResponse } from 'libs/api'
import { AxiosError } from 'axios'
import { QueryFilters } from 'react-query/types/core/utils'

const CACHE_TIME = Infinity

const fetchNothing = async (): Promise<any> => undefined

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // retry with exponential backoff and a max of 30 seconds
      retryDelay: (attemptIndex) =>
        Math.min(Math.floor(2000 * 2 ** attemptIndex * Math.random() * 3), 60000 + Math.random() * 1000),
      retry: (failureCount: number, error: unknown) =>
        failureCount < 3 && (error as AxiosError).response?.status !== 404,
    },
  },
})

export const useQuery = <T, V extends object>(
  fetch: (args: V) => Promise<T>,
  variables: V = {} as V,
  config?: Omit<UseQueryOptions<T, unknown, T, [string, V]>, 'queryKey' | 'queryFn'>
) => {
  const queryFn = () => fetch(variables)
  return _useQuery<T, unknown, T, [string, V]>([fetch.name, variables], queryFn, {
    cacheTime: CACHE_TIME,
    staleTime: 10000,
    refetchOnWindowFocus: false,
    ...config,
  })
}

export const useLimitOffsetQuery = <T, V extends object>(
  fetch: (args: V) => Promise<T[]>,
  variables: V = {} as V,
  config?: Omit<UseInfiniteQueryOptions<T[], unknown, T[]>, 'queryKey' | 'queryFn'>
) => {
  const queryFn = ({ pageParam }: QueryFunctionContext) => fetch({ ...variables, ...pageParam })

  const props = useInfiniteQuery<T[], unknown, T[]>([fetch.name, variables], queryFn, {
    getNextPageParam: (lastPage, allPages) => (lastPage.length ? { offset: flatten(allPages).length } : undefined),
    cacheTime: CACHE_TIME,
    staleTime: 0,
    refetchOnWindowFocus: false,
    ...config,
  })

  return {
    ...props,
    fetchNextPage: props.isFetching ? fetchNothing : props.fetchNextPage,
  }
}

export const useListQuery = <T, C extends object>(
  fetch: (cursor: C) => Promise<ListResponse<T, C>>,
  cursor?: C,
  config?: Omit<UseInfiniteQueryOptions<ListResponse<T, C>, unknown, ListResponse<T, C>>, 'queryKey' | 'queryFn'>
): UseInfiniteQueryResult<ListResponse<T, C>> => {
  const queryFn = ({ pageParam }: QueryFunctionContext) => fetch({ ...cursor, ...pageParam })
  const props = useInfiniteQuery<ListResponse<T, C>, unknown, ListResponse<T, C>>([fetch.name, cursor], queryFn, {
    getNextPageParam: (lastPage) => lastPage.nextCursor ?? undefined,
    cacheTime: CACHE_TIME,
    staleTime: 0,
    refetchOnWindowFocus: false,
    ...config,
  })

  return {
    ...props,
    fetchNextPage: props.isFetching ? fetchNothing : props.fetchNextPage,
  }
}

export const refetchQueries = <T, V extends object>(
  fetch: (args: V) => Promise<T>,
  variables: V = {} as V,
  filters?: QueryFilters
) => queryClient.refetchQueries([fetch.name, variables], filters)

export const setQueryData = <T, V extends object>(
  fetch: (...args: V[]) => Promise<T>,
  variables: V = {} as V,
  dataOrUpdater: T | ((oldData: T | undefined) => T)
) => queryClient.setQueryData([fetch.name, variables], dataOrUpdater)

export const getQueryData = <T, V extends object>(fetch: (...args: V[]) => Promise<T>, variables: V = {} as V) =>
  queryClient.getQueryData<T>([fetch.name, variables])

export const setInfiniteQueryData = <T, V extends object>(
  fetch: (...args: V[]) => Promise<T[]>,
  variables: V = {} as V,
  updater: (oldData: T[]) => T[]
) =>
  queryClient.setQueryData<InfiniteData<T[]>>([fetch.name, variables], (oldData) => {
    const data = flatten(oldData?.pages)
    const newData = updater(data)
    if (data === newData && oldData) {
      return oldData // avoid mutation
    }
    return {
      pages: chunk(newData, oldData?.pages[0].length ?? 15),
      pageParams: oldData?.pageParams ?? [],
    }
  })
