import { API } from 'aws-amplify'
import { DeviceGroup } from './deviceGroups'
import { DeviceItem, DeviceKind } from './devices'
import { Person, PersonBadge } from './people'
import { Timezone, Zone } from './zones'
import { LimitOffsetCursor, ListResponse, OrderParams } from './types'
import { AuthorizationEventResolution } from './authorizationEventResolutions'
import { getNextCursor, multiValueParam } from './utils'
import { isUndefined, omitBy } from 'lodash-es'

export type AuthorizationEvent = {
  authorization_event_id: string
  authorized: boolean
  created_at: string
  device_id: string
  device_time: string
  note: string
  person_id: string
  resolution_id: string
  resolved_by: string
  resolved_by_user: { family_name: string; given_name: string; user_id: string } | null
  updated_at: string
  device_group?: Pick<DeviceGroup, 'device_group_id' | 'name'>
  device?: Pick<DeviceItem, 'name' | 'thing_id' | 'thing_type_id'>
  zone?: Pick<Zone, 'zone_id' | 'name'>
  badge_string: string
  transit_event?: TransitEvent
  person?: Pick<Person, 'first_name' | 'last_name' | 'person_id' | 'image_url'>
  resolution?: AuthorizationEventResolution
  acknowledged: boolean
  image?: {
    image_url: string
    bounding_box_origin_x: number
    bounding_box_origin_y: number
    bounding_box_width: number
    bounding_box_height: number
  }
  reason?: string
  timezone?: Timezone
}

export interface AuthorizationEventsFilters {
  status?: 'passed' | 'failed'
}

export type AuthorizationEventsFilterParams = {
  badge_status?: string
  person_id?: string
  zone_id?: string[]
  device_id?: string
  access_group_id?: string | string[]
  device_group_id?: string
  device_kind?: DeviceKind
  start_time?: string
  end_time?: string
  status?: boolean // passed === true; failed === false
  with_images?: boolean
  note?: string
  use_inversion_query?: boolean
}

export type TransitEvent = {
  transit_event_id: string
  device_time: string
  device_group_id: string
  person_id: string
  direction: 'secure' | 'unsecure'
  status: 'in_progress' | 'passed' | 'failed'
}

type FetchParams = AuthorizationEventsFilterParams &
  LimitOffsetCursor &
  OrderParams & {
    latest?: boolean
    include_transit_event?: boolean
    reason?: string
  }

export async function fetchAuthorizationEvents(params: FetchParams): Promise<AuthorizationEvent[]> {
  return API.get('authorization-events', '/authorization-events', {
    queryStringParameters: omitBy(
      {
        ...params,
        zone_id: multiValueParam(params.zone_id),
      },
      isUndefined
    ),
  })
}

export async function fetchAuthorizationEventList(
  params: FetchParams
): Promise<ListResponse<AuthorizationEvent, FetchParams>> {
  const response = await API.get('authorization-events', '/authorization-events', {
    queryStringParameters: omitBy(
      {
        ...params,
        zone_id: multiValueParam(params.zone_id),
        responseFormat: 'list',
      },
      isUndefined
    ),
  })
  return {
    ...response,
    nextCursor: getNextCursor(response, { offset: params.offset }),
  }
}

type ResolveAuthorizationEventPayload = Pick<AuthorizationEvent, 'resolution_id' | 'note'>

export async function resolveAuthorizationEvent(id: string, payload: ResolveAuthorizationEventPayload) {
  return API.put('authorization-events', `/authorization-events/${id}`, {
    body: payload,
  })
}

export async function exportAuthorizationEvents(filters: AuthorizationEventsFilterParams): Promise<{ Key: string }> {
  return API.post('authorization-events', '/authorization-events/export', {
    body: filters,
  })
}

export async function exportAuthorizationEventsAsync(filters: AuthorizationEventsFilterParams & { email?: string }) {
  return API.post('authorization-events', '/authorization-events/export-async', {
    body: filters,
  })
}

export type InactivityFilters = {
  days: number
  root_zone_id?: string
}

type FetchInactiveParams = InactivityFilters & LimitOffsetCursor & OrderParams

export type InactiveItem = Pick<Person, 'person_id' | 'first_name' | 'last_name' | 'image_url' | 'employee_string'> & {
  last_event_time: string | null
  badge_enabled: PersonBadge['status']
  locations: Pick<Zone, 'zone_id' | 'name'>[]
}

export async function fetchInactiveList(
  params: FetchInactiveParams
): Promise<ListResponse<InactiveItem, FetchInactiveParams>> {
  const response = await API.get('authorization-events', '/authorization-events/inactive', {
    queryStringParameters: omitBy(
      {
        ...params,
        responseFormat: 'list',
      },
      isUndefined
    ),
  })
  return {
    ...response,
    nextCursor: getNextCursor(response, { offset: params.offset }),
  }
}

export async function exportInactiveItems(
  filters: InactivityFilters & OrderParams & { email: string }
): Promise<{ Key: string }> {
  return API.post('authorization-events', '/authorization-events/inactive/export-async', {
    body: filters,
  })
}

export type OccupancyFilters = {
  start_time: string
  end_time?: string
  root_zone_id?: string
  email?: string
}

type FetchOccupancyParams = OccupancyFilters & LimitOffsetCursor

export type OccupancyItem = Pick<Person, 'person_id' | 'first_name' | 'last_name' | 'image_url' | 'employee_string'> & {
  entry_time: string
  zone: Pick<Zone, 'zone_id' | 'name'>
  location: Pick<Zone, 'zone_id' | 'name'>
}

export async function fetchOccupancyList(
  params: FetchOccupancyParams
): Promise<ListResponse<OccupancyItem, FetchOccupancyParams>> {
  const response = await API.get('authorization-events', '/authorization-events/occupancy', {
    queryStringParameters: omitBy(
      {
        ...params,
        responseFormat: 'list',
      },
      isUndefined
    ),
  })
  return {
    ...response,
    nextCursor: getNextCursor(response, { offset: params.offset }),
  }
}

export async function exportOccupancyItems(filters: OccupancyFilters & { email: string }): Promise<{ Key: string }> {
  return API.post('authorization-events', '/authorization-events/occupancy/export-async', {
    body: filters,
  })
}

export type EventMetricType = {
  authorized_count: number
  direction: 'secure' | 'unsecure'
  failed_count: number
  kind: DeviceKind
}

export type FetchZoneMetricsFilters = {
  start_time?: string
  end_time?: string
}

type FetchZoneMetricsParams = { id: string } & FetchZoneMetricsFilters

export async function fetchZoneMetrics({ id, ...filters }: FetchZoneMetricsParams): Promise<EventMetricType[]> {
  return API.get('authorization-events', `/authorization-events/zone-metrics/${id}`, { queryStringParameters: filters })
}

type FetchZoneOverviewParams = { zone_id: string; start_time?: string; end_time?: string }

export type ZoneOverview = {
  devices: { count: number }
  people: { count: number }
  events: { count: number }
  metalDetectorEvents: {
    totalCount: number
    items: AuthorizationEvent[]
  }
}
export type ZoneMetalDetectorOverview = {
  events: { count: number }
  metalDetectorEvents: {
    totalCount: number
    items: AuthorizationEvent[]
  }
}
export type ZoneDevicesOverview = {
  devices: { count: number }
}
export type ZonePeopleOverview = {
  people: { count: number }
}
export async function fetchZoneOverview(filters: FetchZoneOverviewParams): Promise<ZoneOverview> {
  return API.get('authorization-events', `/authorization-events/overview`, { queryStringParameters: filters })
}
export async function fetchZoneMetalDetectorOverview(
  filters: FetchZoneOverviewParams
): Promise<ZoneMetalDetectorOverview> {
  return API.get('authorization-events', `/authorization-events/overview/metal-detector`, {
    queryStringParameters: filters,
  })
}
export async function fetchZonePeopleOverview(filters: FetchZoneOverviewParams): Promise<ZonePeopleOverview> {
  return API.get('authorization-events', `/authorization-events/overview/people`, { queryStringParameters: filters })
}
export async function fetchZoneDevicesOverview(filters: FetchZoneOverviewParams): Promise<ZoneDevicesOverview> {
  return API.get('authorization-events', `/authorization-events/overview/devices`, { queryStringParameters: filters })
}
