import { extendTheme, Theme } from '@chakra-ui/react'
import { styles } from './styles'
import { colors } from './colors'
import { fontSizes } from './fontSizes'
import { components } from './components'
import { shadows } from './shadows'
import { fonts } from './fonts'

type CustomTheme = Theme & {
  colors: typeof colors
  fonts: typeof fonts
  fontSizes: typeof fontSizes
  shadows: typeof shadows
}

const theme: CustomTheme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
  styles,
  fonts,
  shadows,
  colors,
  fontSizes,
  components,
}) as CustomTheme

export default theme
