import React from 'react'
import { Form, Formik } from 'formik'
import { Flex, Stack } from '@chakra-ui/react'
import {
  SubmitButton,
  CancelButton,
  TextField,
  TextareaField,
  SelectField,
  ColorSelectField,
  SwitchField,
  NumberField,
} from 'components/form'
import { createLabel, CreateLabelPayload, Label, updateLabel } from 'libs/api'
import { useNotify, useTranslations, useValidation } from 'hooks'
import translations from './UpsertLabel.i18n.json'
import { Text } from 'components'
import theme from 'theme'
import { useIntl } from 'react-intl'

interface Props {
  item?: Label
  onSuccess: () => void
  onCancel: () => void
}

export const LABEL_COLOR_OPTIONS = [
  { label: translations.redColor, value: theme.colors.red['2'] },
  { label: translations.orangeColor, value: theme.colors.orange['2'] },
  { label: translations.greenColor, value: theme.colors.green['2'] },
  { label: translations.blueColor, value: theme.colors.primary.main },
  { label: translations.purpleColor, value: theme.colors.purple['2'] },
  { label: translations.tealColor, value: theme.colors.teal['2'] },
  { label: translations.grayColor, value: theme.colors.dark['2'] },
]

export const UpsertLabel: React.FC<Props> = ({ onSuccess, onCancel, item }) => {
  const intl = useIntl()
  const t = useTranslations(translations)
  const notify = useNotify()
  const validationSchema = useValidation((rules) =>
    rules.object({
      name: rules.string().required().label(t.nameLabel),
      description: rules.string().required().label(t.descriptionLabel),
      color: rules.string().required().label(t.colorLabel),
    })
  )

  const onSubmit = async (values: CreateLabelPayload) => {
    try {
      await (item ? updateLabel(item, values) : createLabel(values))
      notify.success(item ? t.editSuccess : t.createSuccess)
      onSuccess()
    } catch (e: any) {
      if (e.response?.data?.code === 'duplicated_category') {
        notify.error(t.duplicatedLabel)
      } else {
        notify.error((e as Error).message)
      }
    }
  }

  const timeLimitUnitOptions = [
    { label: t.unitHours, value: 60 * 60 },
    { label: t.unitDays, value: 24 * 60 * 60 },
  ]

  const initialValues = {
    ...(item ?? {
      name: '',
      description: '',
      color: '',
      time_limit: false, // TODO add to API
    }),
    time_limit_number: 24,
    time_limit_unit: timeLimitUnitOptions[0].value,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false} // empty form, height changes and stops Cancel click
    >
      {({ values }) => (
        <Form>
          <Flex direction="column">
            <Text mt={2} mb={6}>
              {t.createDescription}
            </Text>
            <Stack spacing={6}>
              <TextField name="name" label={t.nameLabel} placeholder={t.namePlaceholder} />
              <TextareaField name="description" label={t.descriptionLabel} placeholder={t.descriptionPlaceholder} />
              <ColorSelectField
                name="color"
                placeholder={t.colorPlaceholder}
                options={LABEL_COLOR_OPTIONS.map((item) => ({
                  ...item,
                  label: intl.formatMessage(item.label),
                }))}
              />
              <SwitchField name="time_limit" label={t.timeLimitLabel} />
              {values.time_limit && (
                <Stack isInline spacing={4}>
                  <NumberField name="time_limit_number" flex={1} />
                  <SelectField name="time_limit_unit" options={timeLimitUnitOptions} flex={1} />
                </Stack>
              )}
              <Flex justify="flex-end" align="center">
                <CancelButton onClick={onCancel} mr={4} />
                <SubmitButton>{item ? t.editButton : t.createButton}</SubmitButton>
              </Flex>
            </Stack>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
