import { Styles, OptionTypeBase } from 'react-select'
import theme from 'theme'
import { useColorMode } from '@chakra-ui/react'

const { colors } = theme

export const useSelectStyles = <T extends OptionTypeBase>(): Styles<T, boolean> => {
  const darkMode = useColorMode().colorMode === 'dark'
  const textColor = darkMode ? colors.light[2] : colors.dark[2]
  const filledBorderColor = darkMode ? colors.dark[3] : colors.dark[2]
  const emptyBorderColor = darkMode ? colors.dark[2] : colors.dark[4]
  const selectedOptionColor = darkMode ? colors.primary.main : colors.primary.subtle
  const focusedOptionColor = darkMode ? colors.primary.darker : '#e6f1ff'
  return {
    container: (styles) => ({
      ...styles,
      flex: 1,
    }),
    control: (styles, { hasValue, isDisabled }) => ({
      ...styles,
      borderRadius: 0,
      backgroundColor: darkMode ? 'rgba(255,255,255,0.06)' : colors.light[4],
      borderColor: hasValue && !isDisabled ? filledBorderColor : emptyBorderColor,
      padding: 4,
    }),
    input: (styles) => ({
      ...styles,
      color: textColor,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: colors.dark[3],
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? colors.dark[1] : colors.light[4],
      zIndex: 10,
    }),
    option: (styles, { isDisabled, isSelected, isFocused }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? selectedOptionColor
        : isFocused
        ? focusedOptionColor
        : undefined,
      color: textColor,
      fontWeight: 'bold',
      fontFamily: theme.fonts.label,
      ':active': {
        backgroundColor: !isDisabled && selectedOptionColor,
      },
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? emptyBorderColor : textColor,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: darkMode ? colors.dark[2] : colors.light[1],
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: textColor,
      backgroundColor: data.isFixed ? emptyBorderColor : darkMode ? colors.dark[2] : colors.light[1],
      fontSize: '100%',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: textColor,
      backgroundColor: darkMode ? colors.dark[2] : colors.light[1],
      display: data.isFixed ? 'none' : undefined,
    }),
  }
}
