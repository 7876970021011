import { Flex, Spinner } from '@chakra-ui/react'
import { Auth } from 'aws-amplify'
import React, { useCallback, useEffect, useState } from 'react'
import MainRouter from './modules/Router/MainRouter'
import { CognitoUser } from 'types'
import { ConfirmModalProvider, useSWUpdate } from 'hooks'
import { AuthContextProvider } from 'components/AuthContextProvider'
import { AuthCredentialsProvider } from 'components/AuthCredentialsProvider'
import { WebsocketProvider } from 'components/WebsocketProvider'
import * as Sentry from '@sentry/react'
import { useConnectionStatus } from 'hooks/useConnectionStatus'
import { omit } from 'lodash-es'
import CacheBuster from './CacheBuster'
import { TenantProvider } from './components'
import { AuthorizationEventsProvider } from 'components/AuthorizationEventsProvider'

type Props = {
  loading: boolean
  isLatestVersion: boolean
  refreshCacheAndReload: () => void
}

function App() {
  const [currentUser, setCurrentUser] = useState<CognitoUser>()
  const [isChecking, setIsChecking] = useState(true)
  const online = useConnectionStatus()
  useSWUpdate()

  const checkCurrentUser = useCallback(async () => {
    try {
      if (online) {
        const user = await Auth.currentAuthenticatedUser()
        setCurrentUser(user)
        const poolWithoutStorage = omit(user.pool, 'storage')
        const userWithoutStorage = omit(user, 'storage')
        localStorage.setItem('currentUser', JSON.stringify({ ...userWithoutStorage, pool: poolWithoutStorage })) // avoid recursion
      } else {
        const cachedUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
        setCurrentUser(cachedUser)
      }
    } catch (e) {
      if (e !== 'not authenticated') {
        // alert(e)
      }
    }
    setIsChecking(false)
  }, [online])

  useEffect(() => {
    checkCurrentUser()
  }, [checkCurrentUser])

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: Props) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload()
        }

        return (
          <React.Suspense fallback="">
            <AuthContextProvider currentAuthenticatedUser={currentUser} setCurrentAuthenticatedUser={setCurrentUser}>
              <AuthCredentialsProvider isAuthenticated={Boolean(currentUser)}>
                <ConfirmModalProvider>
                  {isChecking ? (
                    <Flex align="center" justify="center" width="100vw" height="100vh">
                      <Spinner size="xl" />
                    </Flex>
                  ) : (
                    <WebsocketProvider>
                      <AuthorizationEventsProvider>
                        <TenantProvider>
                          <MainRouter />
                        </TenantProvider>
                      </AuthorizationEventsProvider>
                    </WebsocketProvider>
                  )}
                </ConfirmModalProvider>
              </AuthCredentialsProvider>
            </AuthContextProvider>
          </React.Suspense>
        )
      }}
    </CacheBuster>
  )
}

export default Sentry.withProfiler(App)
