import React, { useMemo, useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { CancelButton, Stack, SubmitButton } from 'components'
import { TextField } from 'components/form'
import { Form, Formik } from 'formik'
import { useListQuery, useNotify, useTranslations, useValidation } from 'hooks'
import { DeviceLinkFormValues, addDeviceLink, fetchDevicesList, updateDeviceLink } from 'libs/api'
import { DeviceItem, DeviceLink } from 'libs/api/devices'
import Autosuggest, {
  RenderInputComponentProps,
  RenderSuggestionsContainerParams,
  SuggestionsFetchRequestedParams,
} from 'react-autosuggest'
import translations from './DeviceLinkForm.i18n.json'
import { flatten, map } from 'lodash-es'
import { useDebounce } from 'use-debounce/lib'
import { Input } from '@chakra-ui/react'
import styled from '@emotion/styled'

interface Props {
  item?: {
    device: DeviceItem
    link?: DeviceLink
  }
  onSuccess: () => void
  onCancel: () => void
}

const SuggestionContainerWrapper = styled.div`
  position: relative;
  z-index: 200;
`

const SuggestionContainerContent = styled.div`
  position: absolute;
  width: 100%;
  background: white;
  padding: 0 12px;
  box-shadow: 0 0 1px rgb(40 41 61 / 8%), 0px 0.5px 2px rgb(96 97 112 / 16%);

  ul {
    list-style: none;
  }
`

const SuggestionWrapper = styled.div`
  cursor: pointer;
  padding: 12px 0;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const SuggestionMacAddress = styled.span`
  display: block;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
`

const Suggestion = (suggestion: DeviceItem) => {
  return (
    <SuggestionWrapper>
      <span>{suggestion.name}</span>
      <SuggestionMacAddress>{suggestion.mac_address || '--'}</SuggestionMacAddress>
    </SuggestionWrapper>
  )
}

const AutocompleteInput = ({ size, ...props }: RenderInputComponentProps) => {
  return <Input {...props} fontSize="sm" borderRadius={0} py="22px" />
}

const SuggestionContainer = ({ containerProps, children }: RenderSuggestionsContainerParams) => {
  return (
    <SuggestionContainerWrapper>
      <SuggestionContainerContent {...containerProps}>{children}</SuggestionContainerContent>
    </SuggestionContainerWrapper>
  )
}

const getSuggestionValue = (it: DeviceItem) => it.name

export function DeviceLinkForm({ onSuccess, item, onCancel }: Props) {
  const t = useTranslations(translations)
  const snackbar = useNotify()
  const [searchParam, setSearchParam] = useState(item?.link?.device.name || '')
  const [debouncedSearchParam] = useDebounce(searchParam, 500)

  const validationSchema = useValidation((rules) =>
    rules.object({
      to_device: rules.object().required().label(t.toDevice),
    })
  )

  const { data, remove } = useListQuery(fetchDevicesList, {
    limit: 20,
    search: debouncedSearchParam!,
    orderBy: 'name',
    orderDirection: 'ASC',
  })

  const autocompleteItems = useMemo(() => {
    return data?.pages.length
      ? flatten(map(data.pages, 'items') || []).filter((it) => it.thing_id !== item?.device.thing_id)
      : []
  }, [item, data])

  const onSubmit = async (values: DeviceLinkFormValues) => {
    try {
      if (item?.link?.device_link_id) {
        await updateDeviceLink({
          device_link_id: item.link?.device_link_id,
          device_1_id: item?.device.thing_id || '',
          device_2_id: values.to_device?.thing_id || '',
          tag: values.tag,
        })
      } else {
        await addDeviceLink({
          device_1_id: item?.device.thing_id || '',
          device_2_id: values.to_device?.thing_id || '',
          tag: values.tag,
        })
      }

      snackbar.success(t.success)
      onSuccess()
    } catch (e: any) {
      snackbar.error(e)
    }
  }

  const initialValues: DeviceLinkFormValues = {
    to_device: item?.link?.device || undefined,
    tag: item?.link?.tag || '',
  }

  function onSuggestionsFetchRequested(request: SuggestionsFetchRequestedParams) {
    setSearchParam(request.value)
  }

  function onSuggestionsClearRequested() {
    remove()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize validationSchema={validationSchema}>
      {({ setFieldValue }) => {
        return (
          <Form>
            <Flex direction="column">
              <Stack spacing={6} mt={6}>
                <Autosuggest
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  suggestions={autocompleteItems}
                  focusInputOnSuggestionClick={false}
                  renderSuggestion={Suggestion}
                  renderInputComponent={AutocompleteInput}
                  renderSuggestionsContainer={SuggestionContainer}
                  onSuggestionSelected={(e, { suggestion }) => setFieldValue('to_device', suggestion)}
                  inputProps={{
                    value: searchParam,
                    placeholder: '',
                    name: 'to_device',
                    onChange: (e, changeEvent) => {
                      setSearchParam(changeEvent.newValue)
                    },
                  }}
                />
                <TextField name="tag" label={t.tag} />
                <Flex justify="flex-end" align="center">
                  <CancelButton mr={4} onClick={onCancel} />
                  <SubmitButton>{item?.link ? t.updateLink : t.addLink}</SubmitButton>
                </Flex>
              </Stack>
            </Flex>
          </Form>
        )
      }}
    </Formik>
  )
}
