import React, { MutableRefObject, useContext, useRef, ReactNode, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useCurrentDate } from './hooks/useCurrentDate'
import { useCurrentUser } from '../../../hooks'
import { Box, Flex, SubtleLink } from 'components'
import { Heading, useColorModeValue } from '@chakra-ui/react'
import { IconButton, UserAvatar } from 'components'
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import { OmniSearch, OmniSearchType } from 'modules/OmniSearch/OmniSearch'
import { useLocation } from 'react-router-dom'
import * as routes from 'routes'
import { can } from 'utils/access'
import { useAuthContext } from 'hooks'
import { useMobileDetect } from 'hooks/useMobileDetect'
import { CurrentRoleDropdown } from './CurrentRoleDropdown'

type ContextProps = { titleRef: MutableRefObject<any>; omniSearchType: MutableRefObject<OmniSearchType> }

const HeaderContext = React.createContext<ContextProps | undefined>(undefined)

export const HeaderBox = ({ children }: { children: ReactNode }) => {
  const titleRef = useRef(null)
  const omniSearchType = useRef('default' as const)
  const currentDate = useCurrentDate()
  const user = useCurrentUser()
  const [isSearchMode, setIsSearchMode] = useState(false)
  const location = useLocation()
  const { currentRole } = useAuthContext()
  const { isMobile } = useMobileDetect()

  const textColor = useColorModeValue('gray.700', 'gray.200')

  useEffect(() => {
    setIsSearchMode(false)
    omniSearchType.current = 'default'
  }, [location])

  const canOmniSearch =
    can(currentRole, 'list', 'people') || can(currentRole, 'list', 'things') || can(currentRole, 'list', 'users')
  return (
    <>
      <HeaderContext.Provider value={{ titleRef, omniSearchType }}>
        <Flex pt={8} pb={8} justifyContent="space-between" lineHeight="2rem">
          <Heading size="lg" ref={titleRef} color={textColor} display="flex" alignItems="center" />
          <Flex color={textColor} alignItems="center">
            <Box color={textColor} mr={5} display={['none', 'block']}>
              {currentDate}
            </Box>
            {canOmniSearch && !isMobile() && (
              <IconButton
                aria-label="search"
                shade={2}
                isRound
                variant="ghost"
                mx={5}
                onClick={() => setIsSearchMode(!isSearchMode)}
              >
                {isSearchMode ? <CloseIcon boxSize={4} /> : <SearchIcon boxSize={4} />}
              </IconButton>
            )}
            <CurrentRoleDropdown display={['none', 'flex']} />
            <SubtleLink
              to={routes.userDetailWithUsername(user.username)}
              display={['none', 'flex']}
              alignItems="center"
            >
              <UserAvatar name={user.fullName} size="xs" ml={5} />
              <Box color={textColor} fontWeight="bold" ml={2}>
                {user.username}
              </Box>
            </SubtleLink>
          </Flex>
        </Flex>
        {canOmniSearch && isSearchMode ? <OmniSearch type={omniSearchType.current} /> : children}
      </HeaderContext.Provider>
    </>
  )
}

export const HeaderTitle = ({ children }: { children: ReactNode }) => {
  const context = useContext(HeaderContext)

  if (!context) {
    throw Error('`HeaderTitle` must be inside `HeaderContext`')
  }
  if (!context.titleRef.current) {
    return null
  }

  return ReactDOM.createPortal(children, context.titleRef.current)
}

export function useOmniSearchType(type: OmniSearchType) {
  const context = useContext(HeaderContext)

  if (!context) {
    throw Error('`useOmniSearchType` must be inside `HeaderContext`')
  }

  context.omniSearchType.current = type
}
